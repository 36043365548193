import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DrawerContext } from '../context/DrawerContext';
import { UserContext } from '../context/UserContext';
import { getProfileAPI } from '../service/UserService';
import { logOut } from '../utils/constants';

export default function SideBar() {
  const drawerRef = useRef(null);
  const [isSideBarOpen, setIsSideBarOpen] = useContext(DrawerContext);
  const { user } = useContext(UserContext);
  const history = useHistory();
  history.listen((location, action) => {
    if (action === 'PUSH') {
      setIsSideBarOpen(false);
    }
  });
  const [sideNavs, setSideNavs] = useState([
    {
      title: 'Profile',
      icon: require('../assets/img/profile_icon.png'),
      route: '/home/profile',
    },
    {
      title: 'Create ID',
      icon: require('../assets/img/add_icon.png'),
      route: '/home/ids/create',
    },
    {
      title: 'Withdrawal details',
      icon: require('../assets/img/withdraw_icon.png'),
      route: '/home/withdrawal',
    },
    // {
    //   title: 'Refer & Earn',
    //   icon: require('../assets/img/refer_icon.png'),
    //   route: '/home/refer-and-earn',
    // },
    {
      title: 'Terms & Conditions',
      icon: require('../assets/img/terms_icon.png'),
      route: '/home/terms-and-conditions',
    },
    {
      title: 'Notifications',
      icon: require('../assets/img/notif_icon.png'),
      route: '/home/notifications',
    },
    {
      title: 'Helpdesk',
      icon: require('../assets/img/helpdesk_icon.png'),
      route: '/home/helpdesk',
    },
    // {
    //   title: 'How to use',
    //   icon: require('../assets/img/play_icon.png'),
    //   route: '/home/how-to-use',
    // },
  ]);
  const handleOutsideClick = (e) => {
    if (drawerRef.current && !drawerRef.current.contains(e.target)) {
      setIsSideBarOpen(false);
    }
  };

  return (
    <div className='w-[100%] fixed z-10  justify-start'>
      <div class='relative side_drawer ' onClick={handleOutsideClick}>
        <div class='flex w-72 flex-col sm:flex-row sm:justify-around'>
          <div ref={drawerRef} class='w-72 bg-[#373D41] h-screen'>
            <div class=' flex bg-[#212528] flex-col items-start text-left justify-start p-5'>
              <Link to='/home'>
                <img
                  class='h-20 mt-5'
                  src={require('../assets/img/w-logo.png')}
                />
              </Link>
              <p class='text-white text-sm mt-8 self-start'>{user.User.name}</p>
              <p class='text-[#FFBF44] text-sm mt-1 self-start'>
                Wallet Balance : {'₹' + user['Account Details'].balance}
              </p>
            </div>
            <nav class='mt-5 px-3 '>
              {sideNavs.map(({ icon, title, route }, index) => (
                <Link
                  key={index}
                  class='hover:text-gray-200  flex items-center p-2 my-3    text-gray-600 dark:text-gray-400 rounded-lg '
                  to={route}
                >
                  <img class='h-4 w-4' src={icon} />
                  <span class='mx-5 text-sm text-white font-normal'>
                    {title}
                  </span>
                </Link>
              ))}
              <div
                class='hover:text-gray-200  flex items-center p-2 my-3    text-gray-600 dark:text-gray-400 rounded-lg '
                onClick={logOut}
              >
                <img
                  class='h-4 w-4'
                  src={require('../assets/img/log_out_icon.png')}
                />
                <span class='mx-5 text-sm font-normal  text-white'>
                  Log Out
                </span>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
