import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import TopNavigation from "../../components/TopNavigation";
import { getTransactionDetailsAPI } from "../../service/UserService";

export default function Transaction(router) {
  const { transactionId } = router.match.params;
  const [transactionDetails, setTransactionDetails] = useState({});
  const getTransactionDetails = async () => {
    const {
      data: { userTransaction },
    } = await getTransactionDetailsAPI(transactionId);
    setTransactionDetails(userTransaction);
  };
  useEffect(() => {
    getTransactionDetails();
  }, [transactionId]);

  return (
    <>
      <TopNavigation isBackNav={true} title="Transaction Details" />
      <div className="flex w-[100%] flex-row justify-between align-middle items-center">
        <h1 className="text-white mt-5 self-start text-xl">
          {transactionDetails?.transactionType?.replaceAll("_", " ")}
        </h1>
        <span
          style={{
            backgroundColor:
              transactionDetails?.transactionStatus == "REJECTED"
                ? "tomato"
                : transactionDetails?.transactionStatus == "PENDING"
                ? "yellow"
                : "green",
          }}
          className="px-2 mt-5 py-1 text-[10px] text-white  rounded-md"
        >
          {transactionDetails?.transactionStatus}
        </span>
      </div>
      <div className="w-[100%] mt-5 text-gray-300 p-2 rounded-lg bg-[#1B1E21] text-[8px]">
        <div className="text-md font-bold border-b-[1px] w-[100%] mb-2 border-gray-600 ">
          Transaction Details
        </div>
        <div className="flex px-2 flex-row w-[100%] justify-between">
          <span>Requested Coints</span>
          {transactionDetails?.coins}
        </div>
        <div className="flex px-2 flex-row w-[100%] justify-between">
          <span>Approved Coints</span>
          {transactionDetails?.approvedCoins}
        </div>
        <div className="flex px-2 flex-row w-[100%] justify-between">
          <span>Transaction ID</span>
          {transactionDetails?.transactionId}
        </div>
        <div className="flex px-2 flex-row w-[100%] justify-between">
          <span>Requested Date</span>
          {new Date(transactionDetails?.requestGenerated).toString()}
        </div>
        <div className="flex px-2 flex-row w-[100%] justify-between">
          <span>Approved Date</span>
          {new Date(transactionDetails?.requestValidated).toString()}
        </div>
        {transactionDetails.paymentType && (
          <div className="flex px-2 flex-row w-[100%] justify-between">
            <span>Payment Type</span>
            {transactionDetails.paymentType.replaceAll("_", " ")}
          </div>
        )}
      </div>
    </>
  );
}
