import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import PhoneInput from "../components/PhoneInput";
import { LoadingContext } from "../context/LoadingContext";
import { checkPhoneNumberAPI } from "../service/AuthService";
import { downloadAPI } from "../service/UserService";

export default function GetStarted() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("IN");
  const [validationError, setValidationError] = useState(true);
  const [validationMessage, setValidationMessage] = useState({
    isValid: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  const history = useHistory();

  const handleSubmit = async () => {
    if (validationError || phoneNumber === "") {
      setValidationMessage({
        isValid: false,
        message: "Phone number is required",
      });
      setValidationError(true);
      return;
    }

    setIsLoading(true);

    try {
      const { statusCode, response } = await checkPhoneNumberAPI(phoneNumber);
      if (statusCode === 200) {
        setIsLoading(false);
        history.push("/login", { phoneNumber });
      } else {
        setIsLoading(false);
        history.push("/register", { phoneNumber });
      }
    } catch (error) {
      setIsLoading(false);
      toast(error.message, {
        type: "error",
      });
    }
  };

  const downloadApp = async () => {
    const {
      data: {
        data: { android },
      },
    } = await downloadAPI();
    return android;
  };

  return (
    <div className="relative h-[100%]">
    
      <div className="absolute inset-0 bg-center bg-cover " style={{ backgroundImage: `url(${require("../assets/img/virat.png")})` ,backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></div>
      
      <div className="absolute inset-0 bg-center bg-cover" style={{ backgroundImage: `url(${require("../assets/img/transparent-bg.png")})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
      
      <div className="component_body items-center justify-center relative z-10">
        <div className="h-[50%] w-[100%] flex items-end justify-center">
          <img
            className="w-[30%] sm:w-[25%] justify-center absolute md:t-30 sm:t-20"
            src={require("../assets/img/w-logo.png")}
          />
        </div>
        <div className="w-[90%] h-[50%] sm:w-[60%] flex flex-col items-center justify-end">
          <div className="phone-input-wrapper">
          <p className="mb-1 text-gray-300 text-sm">Let’s Get Started !</p>
            <PhoneInput
              selectedCountry={selectedCountry}
              handleSelectedCountry={setSelectedCountry}
              handleInputChange={setPhoneNumber}
              value={phoneNumber}
              setValidationError={setValidationError}
              bg={"#1A1D20"}
              shadow={true}
              validationError={validationMessage}
            />
            <div className="vertical-line"></div>
          </div>
          <button
            onClick={handleSubmit}
            className="w-[75%]   yellow_button font-bold p-3 mt-5 text-black flex justify-center items-center"

          >
            Submit
          </button>
        </div>
        <a className="support_icon mb-8" 
        href="https://wa.me/+447537102026"
        target="_blank"
        rel="noopener noreferrer"
        style={{ cursor: 'pointer' }}
        >
          <img src={require("../assets/img/whatsapp-icon.png")} alt="whatsapp" />
          Whatsapp Support
        </a>

        {/* <div className="flex w-[100%] flex-row mb-5 items-center justify-center">
          <div
            onClick={() => {
              downloadApp().then((url) => {
                window.open(url, "_blank");
              });
            }}
            className="bg-[#1A1D20] mr-5 cursor-pointer rounded-lg px-2 py-5 flex flex-row items-center"
          >
            <img
              className="w-[20px] h-[20px] mr-2"
              src={require("../assets/img/android.png")}
              alt="android"
            />
            <p className="text-gray-300 text-sm text-center">
              DOWNLOAD ANDROID APP
            </p>
          </div>
          <div
             onClick={() => {
              downloadApp().then((url) => {
                window.open(url, "_blank");
              });
            }}
            className="bg-[#1A1D20] cursor-pointer rounded-lg px-2 py-5 flex flex-row items-center"
          >
            <img
              className="w-[20px] h-[20px] mr-2"
              src={require("../assets/img/ios.png")}
              alt="ios"
            />
            <p className="text-gray-300 text-sm text-center">DOWNLOAD IOS APP</p>
          </div>
        </div> */}
      </div>
    </div>
  );
}
