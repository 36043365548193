import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  BlackPlusIcon,
  BluePlusIcon,
  NextIcon,
} from "../../assets/svg/SvgComponents";
import IconId from "../../assets/svg/icon-id.svg";
import PlusIcon from "../../assets/img/plus_home_icon.png";
import TopNavigation from "../../components/TopNavigation";
import WalletCard from "../../components/UX/Wallet";
import {
  getAllIdsAPI,
  getBannerAPI,
  getSignedUrlAPI,
} from "../../service/UserService";
import "./home.scss";

import { toast } from "react-toastify";
import Slider from "../../components/Slider";
import { UserContext } from "../../context/UserContext";
export default function HomePage() {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [banner, setBanner] = useState([
    {
      url: "https://www.w3schools.com/w3css/img_lights.jpg",
    },
    {
      url: "https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg",
    },
  ]);
  const [myIds, setMyIds] = useState([]);
  const [isSkeletonLoaderVisible, setIsSkeletonLoaderVisible] = useState(false);
  const { user } = useContext(UserContext);
  const history = useHistory();
  const getDashboardBanner = async () => {
    const { data } = await getBannerAPI();
    let banners_ = data.banners;
    for (let index = 0; index < banners_.length; index++) {
      const {
        data: { url },
      } = await getSignedUrlAPI(banners_[index].bannerUrl);

      banners_[index].bannerUrl = url;
    }
    setBanner(data.banners);
    setIsSkeletonLoaderVisible(false);
  };
  const getAllIds = async () => {
    const {
      data: { User_IDs },
    } = await getAllIdsAPI();
    let ids = User_IDs;
    for (let index = 0; index < ids.length; index++) {
      const {
        data: { url },
      } = await getSignedUrlAPI(ids[index].exchangeIconUrl);

      ids[index].exchangeIconUrl = url;
    }
    setMyIds(ids);
  };
  useEffect(() => {
    getDashboardBanner();
    getAllIds();
  }, []);
  const copyToClipboard = (url) => {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast("Copied to clipboard", { type: "success" });
  };

  return (
    <div className="home  w-[100%] h-[auto]">
      <div className="head">
        <TopNavigation showNotification={true} showBankIcon={true} />
        <WalletCard />
        <div className="banner-box bg-black">
          <Slider items={banner} />
        </div>
      </div>

      <div className=" w-[100%] pl-[15px] pr-[15px] h-[auto] mt-[100px] flex flex-col  items-center align-middle">
        {/* <div className='action-card w-[30%]'>
          <div className='flex flex-col gap-[20px] justify-center w-[100%]'>
            <div className='flex w-[100%] gap-[27px]  items-center justify-between'>
              <i className='icon-60 go-exchange'></i>
              <div className='flex-[1]'>
                <h5>Go Exchange 9</h5>
                <div className='flex items-center gap-[8px]'>
                  <i className='icon-16 user'></i>
                  <p className='username'>{user?.User?.name}</p>
                </div>
              </div>
              <i className='icon forword-arrow'></i>
            </div>
            <div className='flex justify-center  gap-[20px]'>
              <div
                className='card'
                onClick={() => history.push('/home/deposit')}
              >
                <i className='icon-20 deposite-green'></i>
                <p>Deposit</p>
              </div>
              <div
                className='card'
                onClick={() => history.push('/home/withdraw')}
              >
                <i className='icon-20 withdraw-red'></i>
                <p>Withdraw</p>
              </div>
              <div className='card opacity-[0.5]'>
                <i className='icon-20 key'></i>
                <p>Password</p>
              </div>
            </div>
          </div>
        </div> */}

        {/* {myIds.length == 0 ? ( */}
        {/* <Link to={'/home/ids/create'}>
            <span className='flex flex-row inner_shadow  justify-between bg-[#1B1E21] items-center rounded-full align-center p-[7px] w-[150px]'>
              <button className='btn-circle'>
                <BlackPlusIcon />
              </button>
              <span className='text-white ml-3 w-[55%] text-left'>Create</span>
            </span>
          </Link> */}
        {/* <div className='support_icon mt-8'>
          <img src={require('../../assets/img/whatsapp_icon.png')} />
          <p className='mx-4'>
            <a
              href="https://wa.me/+447537102026"
              target="_blank"
              rel="noopener noreferrer"
              style={{ cursor: 'pointer' }}
            >
              Whatsapp Support
            </a>
          </p>

        </div> */}
        {/* // ) : ( */}
        <div className="w-[100%] p-2">
          <div className="flex flex-row justify-between text-lg w-[100%]">
            <span className="text-gray-300">My ID's</span>
            <div>
              <span
                onClick={() => history.push("/home/ids")}
                className="text-gray-300 text-sm bg-[#1B1E21] p-2 rounded-lg mr-2 cursor-pointer"
              >
                View All
              </span>
              <span
                onClick={() => history.push("/home/ids/create")}
                className="text-gray-300 text-sm bg-[#1B1E21] p-2 rounded-lg cursor-pointer"
              >
                Create
              </span>
            </div>
          </div>
          <div className="mt-[10px] mb-[75px]">
            <Slider items={myIds} type="myIds" />
            <div className="support_icon  p-[30px] flex justify-center mx-auto w-[50%]">
              <img src={require("../../assets/img/whatsapp_icon.png")} />
              <p className="mx-4">
                <a
                  href="https://wa.me/+447537102026"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  Support
                </a>
              </p>
            </div>
          </div>
          {/* <div className='flex flex-col mb-20'> */}
          {/* {myIds.map(
                (
                  {
                    exchangeIconUrl,
                    exchangeUrl,
                    userNameExchange,
                    balance,
                    id,
                    userId,
                    userIdExchange,
                    exchangeName,
                  },
                  index
                ) => (
                  <div className='w-[100%] '>
                    <div className='flex self-center flex-row px-2 py-4 bg-[#23272b] items-center justify-between align-middle mt-2  w-[100%]'>
                      <div className='w-[80%] flex flex-row align-middle items-center'>
                        <img
                          className='w-14 mr-5 rounded-full'
                          src={exchangeIconUrl}
                        />
                        <div className='flex flex-col'>
                          <span className='text-gray-300 flex '>
                            {exchangeUrl.replace('https://', '')}{' '}
                            <svg
                              onClick={() => {
                                window.open(exchangeUrl, '_blank');
                              }}
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='currentColor'
                              className='w-4 h-4 ml-2 mt-1'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M13.5 6kH5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25'
                              />
                            </svg>
                          </span>
                          <span className='text-[#FFFFFF61] flex row text-sm'>
                            <img className='h-[20px] mr-2' src={IconId} />
                            {userNameExchange}
                            <svg
                              onClick={() => {
                                copyToClipboard(userNameExchange);
                              }}
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='white'
                              className='w-4 h-4 ml-2 '
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75'
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='flex row  w-[100%] justify-center bg-[#1B1E21]'>
                      <div
                        onClick={() =>
                          history.push('/home/account-deposit-withdraw', {
                            exchangeData: {
                              exchangeIconUrl,
                              exchangeUrl,
                              userNameExchange,
                              balance,
                              id,
                              userId,
                              userIdExchange,
                              exchangeName,
                              isWithdraw: false,
                            },
                          })
                        }
                        className='w-[50%] text-center cursor-pointer p-2 text-sm text-gray-300'
                      >
                        <span className='text-green-400 text-xl'>↑</span>{' '}
                        DEPOSIT
                      </div>
                      <div
                        onClick={() =>
                          history.push('/home/account-deposit-withdraw', {
                            exchangeData: {
                              exchangeIconUrl,
                              exchangeUrl,
                              userNameExchange,
                              balance,
                              id,
                              userId,
                              userIdExchange,
                              exchangeName,
                              isWithdraw: true,
                            },
                          })
                        }
                        className='w-[50%] cursor-pointer text-center text-sm p-2 text-gray-300'
                      >
                        <span className='text-red-400 text-xl'>↓</span> WITHDRAW
                      </div>
                    </div>
                  </div>
                )
              )} */}

          {/* {myIds.length===0 &&(
                <p style={{margin:'auto', color:"white", fontSize:"16px"}}>No Id available, Please Create</p>
              )} */}
          {/* </div> */}
        </div>
        {/* )} */}
      </div>
    </div>
  );
}
