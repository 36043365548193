import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination'; // Pagination styles
import IconId from '../../../src/assets/svg/icon-id.svg';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper/modules';
import "./index.css"
import Lock from '../../assets/svg/icon-password.svg'

const Slider = ({ items, type }) => {

  const history = useHistory();

  const copyToClipboard = (url) => {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast('Copied to clipboard', { type: 'success' });
  };

  return (
    <Swiper slidesPerView={1} 
    modules={[Pagination]}
    pagination={{ clickable: false }}
    onSlideChange={(swiper) => {
      if (swiper.isBeginning) {
        swiper.allowSlidePrev = false; // Disable sliding to the left
      } else {
        swiper.allowSlidePrev = true; // Enable sliding to the left
      }
      if (swiper.isEnd) {
        swiper.allowSlideNext = false; // Prevent sliding beyond the last slide
      } else {
        swiper.allowSlideNext = true; // Allow sliding for other slides
      }
    }}
    >
      {type === 'myIds' ? (
        items.map(
          (
            {
              exchangeIconUrl,
              exchangeUrl,
              userNameExchange,
              balance,
              id,
              userId,
              userIdExchange,
              exchangeName,
              passwordExchange
            },
            index
          ) => (
            <SwiperSlide key={index}>
              <div className='flex flex-col mb-20'>
                <div className='w-[100%] '>
                <div className='flex flex-col self-center px-2 py-4 bg-[#23272b] mt-2 w-[100%]'>
  {/* Row 1: Exchange icon and URL */}
  <div className='w-[100%] flex flex-row items-center justify-between'>
    <div className='w-[80%] flex flex-row items-center'>
      <div className='bg-black rounded-full w-14 h-14 mr-2 flex items-center justify-center'>
        <img className='w-[80%]' src={exchangeIconUrl} alt="Exchange Icon" />
      </div>
      <div className='flex flex-col'>
        <span className='text-gray-300 flex items-center'>
          {exchangeUrl.replace('https://', '')}{' '}
          <svg
            onClick={() => window.open(exchangeUrl, '_blank')}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-4 h-4 ml-2 mt-1 cursor-pointer'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M13.5 6kH5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25'
            />
          </svg>
        </span>
      </div>
    </div>
  </div>

  {/* Row 2: IconId and usernameExchange */}
  <div className='w-[100%] flex flex-row items-center justify-between mt-2'>
    <div className='flex flex-row items-center gap-[10px]'>
      <img className='h-[20px]' src={IconId} alt='Icon' />
      <span className='text-[#FFFFFF61] text-sm'>{userNameExchange}</span>
    </div>
    <svg
      onClick={() => copyToClipboard(userNameExchange)}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={1.5}
      stroke='white'
      className='w-4 h-4 cursor-pointer'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75'
      />
    </svg>
  </div>

  {/* Row 3: Password and copy icon */}
  <div className='w-[100%] flex flex-row items-center justify-between mt-2'>
    <div className='flex flex-row items-center gap-[10px]'>
      <img className='h-[20px]' src={Lock} alt='Password Icon' />
      <span className='text-[#FFFFFF61] text-sm'>{passwordExchange}</span>
    </div>
    <svg
      onClick={() => copyToClipboard(passwordExchange)}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={1.5}
      stroke='white'
      className='w-4 h-4 cursor-pointer'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75'
      />
    </svg>
  </div>
</div>









                  
                  <div className='flex row  w-[100%] justify-center bg-[#1B1E21]'>
                    <div
                      onClick={() =>
                        history.push('/home/account-deposit-withdraw', {
                          exchangeData: {
                            exchangeIconUrl,
                            exchangeUrl,
                            userNameExchange,
                            balance,
                            id,
                            userId,
                            userIdExchange,
                            exchangeName,
                            isWithdraw: false,
                          },
                        })
                      }
                      className='w-[50%] text-center cursor-pointer p-2 text-sm text-gray-300'
                    >
                      <span className='text-green-400 text-xl'>↑</span>{' '}
                      DEPOSIT
                    </div>
                    <div
                      onClick={() =>
                        history.push('/home/account-deposit-withdraw', {
                          exchangeData: {
                            exchangeIconUrl,
                            exchangeUrl,
                            userNameExchange,
                            balance,
                            id,
                            userId,
                            userIdExchange,
                            exchangeName,
                            isWithdraw: true,
                          },
                        })
                      }
                      
                      className='w-[50%] cursor-pointer text-center text-sm p-2 text-gray-300'
                    >
                      <span className='text-red-400 text-xl'>↓</span> WITHDRAW
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          )
        )
      ) : (
        items.map((item, index) => (
          <SwiperSlide key={index}>
            <img src={item.bannerUrl} alt="Banner" style={{ width: '100%', height: '230px'}} />
          </SwiperSlide>
        ))
      )}
    </Swiper>
  );
};

export default Slider;




// import React from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';

// const Slider = ({ items }) => {
//   return (
//     <Swiper
//       slidesPerView={1}
//       onSlideChange={() => console.log('slide change')}
//       onSwiper={(swiper) => console.log(swiper)}
//     >
//       {items.map((item) => (
//         <SwiperSlide key={item.id}>
//           <img src={item.bannerUrl} alt={'banner'} />
//         </SwiperSlide>
//       ))}
//     </Swiper>
//   );
// };

// export default Slider;
